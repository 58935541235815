<template>
  <div>
    <div>
      <div class="table-padding">
        <div class="filter-sorting-container">
          <multiple-filter
            :filter-fields="filterFields"
            @on-apply-filter="onApplyFilter"
          />
        </div>
        <div
          v-if="loading"
          class="spinner-body"
        >
          <b-spinner />
        </div>
        <div
          v-else
          class="table-responsive"
        >
          <!-- Table section -->
          <p-table
            :fields="fields"
            :items="items"
          >
            <template #empty>
              <p
                class="
                    h2
                    d-flex
                    table-empty-message
                    justify-content-center
                    align-items-center
                    gap-2
                  "
              >
                <b-icon
                  icon="exclamation-circle"
                  scale="1"
                />
                <span> No items found </span>
              </p>
            </template>
            <template #cell(entityName)="data">
              <b-link
                class="text-decoration-none text-nowrap"
                :to="{path: `/user-details/${data.item.brandId}`}"
              >
                {{ data.item.entityName }}
              </b-link>
            </template>
            <template #cell(seasonType)="data">
              <span class="text-capitalize">{{ data.item.schedules && SCHEDULED_COLLECTION_TYPES[data.item.schedules.seasonType] }}</span>
            </template>
            <template #cell(month)="data">
              <span class="text-capitalize">{{ data.item.schedules && GET_MONTHS[data.item.schedules.month] }}</span>
            </template>
            <template #cell(updatedAt)="data">
              {{ formattedDate(data.item.updatedAt) }}
            </template>
          </p-table>
        </div>
        <k-table-pagination
          :total-items="itemsCount"
          @emit-current-page="updateCurrentPage"
          @emit-per-page="updatePerPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { formattedDate } from '@/@core/utils/format'
import { apiToastError } from '@/@core/utils/toast'
import { KSearchAndSortMixin } from '@/mixins/k-searbar-section.mixin'
import { PTablePaginationMixin } from '@/mixins/p-table.mixin'
import store from '@/store'
import { GET_SCHEDULED_COLLECTION_LIST } from '@/store/modules/collection.module'
import { getScheduledCollectionFields } from '@/table-fields-constants'
import {
  KTablePagination,
  PTable,
} from '@kingpin-global/kingpin-ui'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { BLink } from 'bootstrap-vue'

const {
  MONTHS,
  SCHEDULED_COLLECTION_TYPE,
} = constants

export default {
  name: 'ScheduledCollectionList',
  components: {
    PTable,
    KTablePagination,
    BLink,
  },
  mixins: [KSearchAndSortMixin, PTablePaginationMixin],
  data() {
    return {
      loaded: false,
      fields: getScheduledCollectionFields(),
      items: [],
      formattedDate,
    }
  },
  computed: {
    loading() {
      return !(this.items.length || this.loaded)
    },
    SCHEDULED_COLLECTION_TYPES() {
      const types = {}
      Object.values(SCHEDULED_COLLECTION_TYPE).forEach(type => {
        types[type.LABEL] = type.TEXT
      })
      return types
    },
    GET_MONTHS() {
      const months = {}
      Object.values(MONTHS).forEach(type => {
        months[type.LABEL] = type.TEXT
      })
      return months
    },
  },
  created() {
    this.initPageContent()
  },
  methods: {
    initPageContent() {
      if (this.items.length > 0) {
        this.updateFilteredItemAndPaginationParams(this.items)
      }
      const queryParams = {
        page: this.currentPage - 1,
        limit: this.perPage,
        search: this.searchText,
      }
      if (this.sortBy) {
        queryParams.sortBy = this.sortBy
        queryParams.asc = !this.isDesc
      }
      if (Object.values(this.filterQuery).length) {
        queryParams.filter = JSON.stringify(this.filterQuery)
      }
      this.loadCollections(queryParams)
    },
    loadCollections(queryParams) {
      store.dispatch(GET_SCHEDULED_COLLECTION_LIST, queryParams)
        .then(res => {
          this.items = res.data.data.scheduledCollections
          this.itemsCount = res.data.data.count
          this.updateFilteredItemAndPaginationParams(this.items)
          this.setFilterFields(res.data.data.filter, this.fields)
          this.loaded = true
        })
        .catch(err => {
          apiToastError(err)
          this.loaded = false
        })
    },
  },
}
</script>
