<template>
  <scheduled-collection-list
    @update-total-items="updateTotalItems"
  />
</template>

<script>
import ScheduledCollectionList from './ScheduledCollectionList.vue'

export default {
  name: 'AllCollections',
  components: {
    ScheduledCollectionList,
  },
  emits: ['update-total-items'],
  methods: {
    updateTotalItems(currentPageCount, totalItemsCount) {
      this.$emit('update-total-items', currentPageCount, totalItemsCount, 'Scheduled Collections')
    },
  },
}
</script>
